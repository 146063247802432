<template>
  <el-card>
    <el-form
      ref="form"
      v-loading="loading"
      :model="form"
      class="assest-save-form mr10"
      label-width="100px"
    >
      <el-row :gutter="20">
        <el-col :span="8">
          <el-form-item
            label="制度名称"
            prop="name"
            :rules="[ { required: true, message: '名称不能为空', trigger: 'change' }]"
          >
            <el-input
              v-model="form.name"
              placeholder="请输入内容"
              maxlength="40"
            />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item
            label="文档目录"
            prop="libraryId"
            :rules="[ { required: true, message: '请选择文档目录', trigger: 'change' }]"
          >
            <el-cascader
              v-model="form.libraryId"
              :options="folders"
              popper-class="cas-class"
              :show-all-levels="false"
              style="width: 100%;"
              :props="{ emitPath: false, expandTrigger: 'hover', value: 'id', label: 'libraryName',checkStrictly: true,showAllLevels:false }"
            />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item
            label="标签"
            prop="labelIdList"
          >
            <el-select
              v-model="form.labelIdList"
              multiple
              placeholder="请选择"
            >
              <el-option
                v-for="item in labels"
                :key="item.id"
                :label="item.labelName"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item label="制度说明">
        <el-input
          v-model="form.description"
          placeholder="请输入内容"
          type="textarea"
          maxlength="200"
        />
      </el-form-item>
      <el-row :gutter="20">
        <el-col :span="8">
          <el-form-item label="模板">

            <el-select
              v-model="form.templateCode"
              placeholder="请选择"
              @change="getTemplateContent"
            >
              <el-option
                v-for="item in formworks"
                :key="item.code"
                :label="item.templateName"
                :value="item.code"
              />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <EditorVue
        v-if="editorInit"
        style="border: 1px solid rgb(204, 204, 204);"
        :content.sync="form"
      />
    </el-form>
    <div class="text-center mt20">
      <el-button @click="back">
        取 消
      </el-button>
      <el-button
        type="primary"
        :disabled="loading"
        @click="submitForm"
      >
        保 存
      </el-button>
    </div>
  </el-card>
</template>

<script>
import { success, error } from '@core/utils/utils'
import {
  DownloadFile, saveOrUpdateRegimeByTemplate, GetLibraryOptions, GetLabelList, GetRegimeById, GetTemplateOptions, GetTemplateContent,
} from '@/api/regime/regime'
import EditorVue from '@core/components/editor-vue/EditorVue.vue'

export default {
  components: {
    EditorVue,
  },
  data() {
    return {
      loading: false,
      editorInit: true,
      form: {
        id: '',
        fileName: '',
        name: '',
        description: '',
        templateCode: '',
        content: '',
        libraryId: 0,
        labelIdList: [],
      },
      templateId: '',
      linkFileName: '',
      labels: [],
      folders: [],
      library: [],
      formworks: [],
      query: this.$route.query,
    }
  },
  mounted() {
    this.navActiveInit(1, '制度流程', '管理框架')
    this.getLibraryOptions()
    this.getLabelList()
    this.getTemplateOptions()
    if (this.query.id) {
      this.getRegimeById()
    }
  },
  destroyed() {
    this.navActiveInit(2, '制度流程', '管理框架', '/regime')
  },
  methods: {
    downloadFile() {
      DownloadFile(this.form.id)
    },
    uploadFileClick() {
      document.getElementById('uploadFileInput').click()
    },
    uploadFileInputChange() {
      const uploadDom = document.getElementById('uploadFileInput')
      const fileVal = uploadDom.files
      this.form.fileName = fileVal[0].name
    },
    back() {
      this.$router.push({
        path: '/regime',
      })
    },
    getTemplateOptions() {
      GetTemplateOptions().then(res => {
        const resData = res.data
        if (resData.code === 0) {
          this.formworks = resData.data
        }
      })
    },
    submitForm() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.loading = true
          saveOrUpdateRegimeByTemplate(this.form).then(res => {
            this.loading = false
            const resData = res.data
            if (resData.code === 0) {
              success(resData.msg)
              this.back()
            } else {
              error(resData.msg)
            }
          })
        }
      })
    },
    listToTree(list) {
      const map = {}
      for (const item of list) {
        item.label = item.libraryName
        map[item.id] = item
      }
      for (const item of list) {
        if (!(item.parentId > 0)) {
          continue
        }
        const parent = map[item.parentId]
        if (parent) {
          if (parent.children === undefined) {
            parent.children = []
          }
          parent.children.push(item)
        }
      }
      return list.filter(i => i.parentId === 0)
    },
    getTemplateContent() {
      this.$confirm('切换模板将导致下面的内容也跟着切换，确认要切换模板么？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        closeOnClickModal: false,
      }).then(() => {
        this.editorInit = false
        if (this.form.templateCode !== '') {
          this.loading = true
          GetTemplateContent(this.form.templateCode).then(res => {
            this.editorInit = true
            this.loading = false
            const resData = res.data
            if (resData.code === 0) {
              this.form.content = resData.data.content
            }
          })
        } else {
          this.form.content = ''
          this.$nextTick(() => {
            this.editorInit = true
          })
        }
      }).catch(() => { this.loading = false })
    },
    getLibraryOptions() {
      GetLibraryOptions().then(res => {
        const resData = res.data
        if (resData.code === 0) {
          this.library = resData.data
          this.folders = this.listToTree(this.library)
        }
      })
    },
    getLabelList() {
      GetLabelList().then(res => {
        const resData = res.data
        if (resData.code === 0) {
          this.labels = resData.data
        }
      })
    },
    getRegimeById() {
      GetRegimeById({ id: Number(this.query.id) }).then(res => {
        const resData = res.data
        if (resData.code === 0) {
          this.form = resData.data
          if (this.form.labelIdList) {
            this.form.labelIdList = this.form.labelIdList.filter(num => num !== 0)
          }
          if (this.query.t === '2') {
            this.form.id = ''
            this.form.name += ' - 副本'
          }
        }
      })
    },
  },
}
</script>

<style>
.assest-save-form {
  padding-right: 10px;
}
.assest-save-form .el-icon-question {
  position: absolute;
  top: 14px;
  right: -20px;
}
.cas-class .el-radio__inner{
  display: none;
}
</style>
